import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { alpha, Chip, ChipTypeMap, useTheme } from "@mui/material";
import { Handle, NodeProps, Position } from "reactflow";
import { JobDto, JobGroupDto } from "../../model/cee/JobDto";
type JobNodeData = {
	job: JobDto,
	jobGroup: JobGroupDto
}

function getJobLabel(job: JobDto) {
	return job.displayName ?? job.currentActivity ?? job.uuid
}

export const JobNode: React.FunctionComponent<NodeProps<JobNodeData>> = (props) => {
	const theme = useTheme();

	const { data: { job, jobGroup }, selected } = props;

	const hasInputs = (job.dependencies?.length ?? 0) > 0;
	const hasOutputs = (jobGroup.jobs?.filter(j => (j.dependencies?.filter(d => d.job?.uuid === job.uuid)?.length ?? 0) > 0)?.length ?? 0) > 0;

	let icon: JSX.Element = <CircleOutlinedIcon />;
	let color: ChipTypeMap["props"]["color"] = 'secondary';

	switch (job.jobStatus) {
		case 'WAITING': icon = <WatchLaterOutlinedIcon />; color = 'default'; break;
		case 'RUNNING': icon = <PendingOutlinedIcon />; color = 'info'; break;
		case 'FINISHED': icon = <CheckCircleOutlineOutlinedIcon />; color = 'success'; break;
		case 'ERROR_FINAL': icon = <ErrorOutlineOutlinedIcon />; color = 'error'; break;
		case 'CANCELED': icon = <CancelOutlinedIcon />; color = 'secondary'; break;
		case 'SKIPPED': icon = <CancelOutlinedIcon />; color = 'secondary'; break;
	}

	const backgroundColor = selected ? alpha((theme.palette as any)[color]?.main ?? theme.palette.secondary.main, theme.palette.action.activatedOpacity) : undefined;
	const boxShadow = selected ? '0 0 3px 2px cornflowerblue' : 'none';

	return <>
		{hasInputs && <Handle type="target" position={Position.Left} />}
		<Chip label={getJobLabel(job)} icon={icon} variant="outlined" clickable color={color} sx={{ cursor: 'pointer', boxShadow, backgroundColor, width: 150, justifyContent: 'start' }} />
		{hasOutputs && <Handle type="source" position={Position.Right} />}
	</>;
}

export default JobNode;