export default class PipelineStepDto {
	id: number | undefined;
	stepIndex: number | undefined;
	type: StepType | undefined;
	name: string | undefined;
	command: string | undefined;
	arguments: Map<string, string> | undefined;
	allowedToFail: boolean | undefined;
	dependentStepIds: number[] | undefined;
	enabled: boolean | undefined;
}

export enum StepType {
	GENERIC = "GENERIC",
	SPECIFIC = "SPECIFIC"
}