import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Collapse, Grid, IconButton, LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { styled } from '@mui/styles';
import { DateTime } from 'luxon';
import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactTimeAgo from 'react-time-ago';
import ReactFlow, { Edge, MarkerType, Node, NodeTypes, Position, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { Context } from '../../context/ContextStore';
import useApiClient from '../../hooks/useApiClient';
import { JobDto, JobFile, JobGroupDto, JobStatus } from '../../model/cee/JobDto';
import PagedResult from '../../model/services/PagedResult';
import '../../theme/ReactFlow.scss';
import FailureMessage from '../FailureMessage';
import JobNode from './JobNode';

const PREFIX = 'JobsTable';

const classes = {
	container: `${PREFIX}-container`,
	green: `${PREFIX}-green`,
	red: `${PREFIX}-red`,
	blue: `${PREFIX}-blue`,
	grey: `${PREFIX}-grey`
}

const StyledBox = styled(Box)(() => ({
	width: '100%',
	height: '100%',
	padding: '1em',
	[`& .${classes.green}`]: {
		color: 'green'
	},
	[`& .${classes.red}`]: {
		color: 'red'
	},
	[`& .${classes.blue}`]: {
		color: 'blue'
	},
	[`& .${classes.grey}`]: {
		color: 'grey'
	},
	[`& .job-group td`]: {
		borderColor: 'lightgray'
	}
}));

function getClassName(status: string | undefined) {
	switch (status) {
		case JobStatus.RUNNING:
			return classes.blue;
		case JobStatus.CANCELED:
		case JobStatus.SKIPPED:
			return classes.grey;
		case JobStatus.FINISHED:
			return classes.green;
		case JobStatus.ERROR_FINAL:
		case JobStatus.ERROR_RETRY:
			return classes.red;
		default:
			return '';
	}
}

const JobsTable: React.FunctionComponent = () => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const { data: jobGroupsQueryData, isLoading: jobGroupsQueryIsLoading } = useQuery(["jobgroups"], () => apiClient.get(`${context.config?.CEE_CORE_URL}/api/v1/jobgroups`, {
		searchParams: {
			page: page,
			size: rowsPerPage,
		}
	})
		.json<PagedResult<JobGroupDto>>(),
		{
			refetchInterval: 2000,
			structuralSharing: true
		}
	);

	const jobGroups = jobGroupsQueryData?.data;
	const allJobGroupsCount = jobGroupsQueryData?.count;

	function handleChangePage(_: unknown, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	}

	return <StyledBox >
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left">Status</TableCell>
						{/* <TableCell align="left">ID</TableCell> */}
						<TableCell align="left">Owner</TableCell>
						<TableCell align="left">Progress</TableCell>
						<TableCell align="left">Created</TableCell>
						{/* <TableCell align="left">Executed</TableCell> */}
						<TableCell align="left">Finished</TableCell>
						<TableCell align="left">Assigned to Platform</TableCell>
						<TableCell align="left">Files</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{jobGroupsQueryIsLoading ? (
						<TableRow>
							{Array(9).fill(0).map((_, i, s) =>
								<TableCell key={`skeleton-${i}`}>
									{(i !== s.length - 1) && <Skeleton animation="wave" />}
								</TableCell>
							)}
						</TableRow>
					) : (
						jobGroups?.map(jobGroup => (
							<JobGroupRows jobGroup={jobGroup} key={jobGroup.uuid} />
						))
					)
					}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							colSpan={9}
							count={allJobGroupsCount ?? 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
							style={{ borderBottom: "0px" }}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	</StyledBox>
}


function hasFinallyFinished(jobStatus: JobStatus | undefined): React.ReactNode {
	return jobStatus === JobStatus.ERROR_FINAL || jobStatus === JobStatus.FINISHED || jobStatus === JobStatus.CANCELED;
}

interface JobGroupRowsProps {
	jobGroup: JobGroupDto
}


function canCancel(job: JobDto): boolean {
	if (job.jobStatus === JobStatus.FINISHED || job.jobStatus === JobStatus.ERROR_FINAL || job.jobStatus === JobStatus.CANCELED) {
		return false;
	}

	return true;
}

function canDelete(job: JobDto): boolean {
	return !canCancel(job);
}
const JobGroupRows: React.FunctionComponent<JobGroupRowsProps> = props => {
	const { jobGroup } = props;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();
	const queryClient = useQueryClient();

	const [selectedJob, setSelectedJob] = useState<JobDto | undefined>();

	const cancelJob = useMutation((jobToCancel: JobDto) => {
		return apiClient.patch(`${context.config?.CEE_CORE_URL}/api/v1/jobs/${jobToCancel.uuid}`, { json: { jobStatus: JobStatus.CANCELLATION_REQUESTED } });
	},
		{
			onSuccess: () => queryClient.invalidateQueries('jobs')
		}
	);

	const deleteJob = useMutation((jobToDelete: JobDto) =>
		apiClient.delete(`${context.config?.CEE_CORE_URL}/api/v1/jobs/${jobToDelete.uuid}`),
		{ onSuccess: () => queryClient.invalidateQueries('jobs') }
	);

	/* Disable job graphs in production for now */
	// eslint-disable-next-line no-process-env
	const showGraph = (process.env.NODE_ENV === 'development') && ((jobGroup.jobs?.length ?? 0) > 1);

	return <React.Fragment key={jobGroup.uuid}>
		{showGraph && <TableRow>
			<TableCell colSpan={9}>
				<JobGraph jobGroup={jobGroup} onJobSelected={job => setSelectedJob(job)} />
			</TableCell>
		</TableRow>}
		{jobGroup.jobs?.map(job => <TableRow key={job.uuid} className={`${((jobGroup.jobs?.length ?? 0) > 1) ? 'job-group' : ''}`} selected={selectedJob?.uuid === job.uuid}>
			<TableCell className={getClassName(job.jobStatus)}>
				<Tooltip placement="top" arrow title={job.uuid}>
					<span>{job.jobStatus}</span>
				</Tooltip>
			</TableCell>
			{/* <TableCell>{job.uuid}</TableCell> */}
			<TableCell>
				{job.ownerDisplayName !== undefined ? job.ownerDisplayName : "(id: " + job.ownerUserId + ")"}
			</TableCell>
			<TableCell width="20%">
				<Box sx={{ mb: 1 }}>
					{job.displayName && <Box sx={{ fontWeight: 'medium' }}>{job.displayName}</Box>}
					<div>{hasFinallyFinished(job.jobStatus) && !job.failureMessage ? 'Done' : job.currentActivity}</div>
					{job.jobStatus === "WAITING" && (job.dependencies?.length ?? 0) > 0 && <div>Waiting for {job.dependencies?.length} job{job.dependencies?.length !== 1 ? 's' : ''} to finish...</div>}
					{job.failureMessage && <FailureMessage content={job.failureMessage} />}
				</Box>
				<Collapse in={!hasFinallyFinished(job.jobStatus) && job.jobStatus !== JobStatus.WAITING && job.jobStatus !== JobStatus.SKIPPED}><LinearProgress variant={job.progress === 0 && !job.estimatedCompletion ? "indeterminate" : "determinate"} value={Math.min(Math.max(job.progress ?? 0, 0), 1) * 100} /></Collapse>
			</TableCell>
			<TableCell align={job.created ? "left" : "center"}>
				{job.created != null
					? <Tooltip placement="top" arrow title={DateTime.fromISO(job.created).toFormat("yyyy-MM-dd, HH:mm:ss")}><span><ReactTimeAgo date={new Date(job.created)} tooltip={false} /></span></Tooltip>
					: '-'}
			</TableCell>
			{/* <TableCell align={job.executed ? "left" : "center"}>{job.executed != null ? DateTime.fromISO(job.executed).toFormat("yyyy-MM-dd, HH:mm:ss") : '-'}</TableCell> */}
			<TableCell>
				{hasFinallyFinished(job.jobStatus)
					? (job.finished != null ? <Tooltip placement="top" arrow title={DateTime.fromISO(job.finished).toFormat("yyyy-MM-dd, HH:mm:ss")}><span><ReactTimeAgo date={new Date(job.finished)} tooltip={false} /></span></Tooltip> : '-')
					: (job.estimatedCompletion ? <ReactTimeAgo date={Math.max(new Date().getTime() + 10000, new Date(job.estimatedCompletion).getTime())} /> : '-')}
			</TableCell>
			<TableCell>
				{job.platformDisplayName ?? ""}
				<Typography color="text.secondary" variant="subtitle2">{job.platformId ?? ""}</Typography>
			</TableCell>
			<TableCell>{Object.entries(job.files ?? {}).map(([key, file], index) =>
				<div key={index}>
					{key}: <a href={`${(file as JobFile).storageServiceBaseUrl}/files/${(file as JobFile).fileId}`}>{(file as JobFile).name ?? (file as JobFile).fileId}</a>
				</div>
			)}
			</TableCell>
			<TableCell>
				<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="flex-end">
					<Grid item>
						<Tooltip title="">
							<IconButton
								size="small"
								disabled
								onClick={() => {
									// setSelectedJob(job);
								}}
							>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					</Grid>
					<Grid item>
						{canCancel(job) &&
							<IconButton
								size="small"
								onClick={() => {
									cancelJob.mutate(job);
								}}
							>
								<BlockIcon />
							</IconButton>
						}
						{canDelete(job) &&
							<IconButton
								size="small"
								onClick={() => {
									deleteJob.mutate(job);
								}}
							>
								<DeleteIcon />
							</IconButton>
						}
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>)}
	</React.Fragment>;
}




export function getJobDepth(job: JobDto | undefined, jobGroup: JobGroupDto, depth: number = 0): number {
	if (depth > 20) { return 20 } /* Prevent infinite loop by dependency cycles */

	return (job?.dependencies?.map(dep => {
		const depJob = getJobInJobGroup(jobGroup, dep.job?.uuid);
		return getJobDepth(depJob, jobGroup, depth + 1)
	}).reduce((a, b) => Math.max(a, b), depth) ?? 0);
}

function getJobInJobGroup(jobGroup: JobGroupDto, uuid?: string) {
	return jobGroup.jobs?.filter(j => j.uuid === uuid)[0];
}

function calculateNodeLayout(jobGroup: JobGroupDto | undefined) {
	let stepsAtDepth = [];

	let nodes: Node[] = [];

	for (let job of jobGroup?.jobs ?? []) {
		let depth = getJobDepth(job, jobGroup!);
		while (stepsAtDepth.length <= depth) { stepsAtDepth.push(0); }
		stepsAtDepth[depth]++;


		nodes.push({
			id: job.uuid,
			type: 'job',
			selectable: true,
			sourcePosition: Position.Right,
			targetPosition: Position.Left,
			position: { x: depth * 200, y: (stepsAtDepth[depth] - 1) * 50 },
			data: {
				job,
				jobGroup
			}
		});
	}

	return nodes;
}

const nodeTypes: NodeTypes = { 'job': JobNode };

interface JobGraphProps {
	jobGroup?: JobGroupDto
	onJobSelected?: (job: JobDto) => void
}

const JobGraph: React.FunctionComponent<JobGraphProps> = props => {
	const { jobGroup, onJobSelected } = props;

	const nodes: Node[] = useMemo(() => calculateNodeLayout(jobGroup), [jobGroup]);

	const edges: Edge[] = useMemo(() => jobGroup?.jobs?.flatMap((job, i) => job.dependencies?.flatMap((dep, j) => {
		if (!dep.job) return [];
		return [{
			id: `${i}-${j}`,
			type: 'simplebezier',
			source: dep.job?.uuid,
			target: job.uuid,
			// style: { stroke: 'black' },
			markerEnd: {
				type: MarkerType.Arrow,
				width: 18,
				height: 18
			}
		}]
	}) ?? []) ?? [], [jobGroup?.jobs]);

	const height = nodes.map(n => n.position.y).reduce((a, b) => Math.max(a, b), 0) + 32;

	return <Box height={height} sx={{ '& .react-flow': { overflow: 'visible!important' }, '& .react-flow__pane': { cursor: 'default' } }}>
		<ReactFlowProvider>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				nodesFocusable={false}
				nodesDraggable={false}
				panOnScroll={false}
				onMouseDownCapture={e => (e.button !== 0 && e.button !== 2) && e.stopPropagation()}
				nodesConnectable={false}
				elementsSelectable={false}
				panOnDrag={false}
				zoomOnScroll={false}
				zoomOnDoubleClick={false}
				zoomOnPinch={false}
				preventScrolling={false}
				proOptions={{ hideAttribution: true }}
				// onSelectionChange={e => onJobSelected?.(e.nodes[0]?.data?.job)}
				onNodeClick={(e, node) => onJobSelected?.(node.data?.job)}
			>
			</ReactFlow>
		</ReactFlowProvider>
	</Box>
};


export { JobsTable, JobGraph };


