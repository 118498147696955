import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Box, Button, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Context } from "../../../context/ContextStore";
import useApiClient from "../../../hooks/useApiClient";
import PagedResult from "../../../model/services/PagedResult";
import RepositoryMappingDto from "../../../model/services/project-service/CeeRepositoryMappingDto";
import ExternalRepositoryDto from "../../../model/services/project-service/ExternalRepositoryDto";
import ConfirmationDialogIconButton from "../../ConfirmationDialogIconButton";
import EditableTextField from "../../EditableTextField";
import AddRepositoryMappingDialog from "./AddRepositoryMappingDialog";

const StyledBox = styled(Box)(() => ({
	padding: '1em',
	display: 'flex',
	flexGrow: 1
}));

const SORT_REPOSITORY_MAPPINGS = (a: RepositoryMappingDto, b: RepositoryMappingDto) => {
	if (a.id === undefined) {
		return 1;
	}

	if (b.id === undefined) {
		return -1;
	}

	return a.id - b.id;
};

interface RepositoryMappingTableProps {
	projectUuid: string | undefined
}

const RepositoryMappingTable: React.FC<RepositoryMappingTableProps> = (props) => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const { projectUuid } = props;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const [page] = useState(DEFAULT_PAGE);
	const [rowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [addRepositoryMappingDialogOpen, setAddRepositoryMappingDialogOpen] = useState(false);

	const repositoryMappingsQuery = useQuery(["project-repository-mappings", { projectUuid: projectUuid }], () => apiClient
		.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositoryMappings`, {
			searchParams: {
				page: page,
				size: rowsPerPage
			}
		})
		.json<PagedResult<RepositoryMappingDto>>()
		.then(res => {
			res.data.sort(SORT_REPOSITORY_MAPPINGS);
			return res;
		}),
		{
			enabled: !!projectUuid
		}
	);

	const repositoryMappings = useMemo(() => repositoryMappingsQuery.data?.data ?? [], [repositoryMappingsQuery.data]);
	// const allRepositoryMappingsCount = repositoryMappingsQuery.data?.count;

	const [externalRepositories, setExternalRepositories] = useState<ExternalRepositoryDto[]>([]);

	useEffect(() => {
		async function getExternalRepositories() {
			return await Promise.all(repositoryMappings
				.filter(repositoryMapping => repositoryMapping.externalRepositoryId !== undefined)
				.map(repositoryMapping => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositories/${repositoryMapping.externalRepositoryId}`).json<ExternalRepositoryDto>())).then((value) => setExternalRepositories(value));
		}
		getExternalRepositories();
	}, [apiClient, context.config?.PROJECTSERVICE_URL, projectUuid, repositoryMappings]);

	return (
		<StyledBox>
			<Grid container direction="column">
				<Grid item paddingBottom="1em">
					<Grid container direction="row" justifyContent="space-between">
						<Grid item>
							<Typography variant="h5">Repository Mappings</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() => setAddRepositoryMappingDialogOpen(true)}
							>
								Add Repository Mapping
							</Button>
						</Grid>
					</Grid>
				</Grid>
				{externalRepositories.length !== 0 && repositoryMappings?.map(repositoryMapping => {
					let repository = externalRepositories.filter(externalRepo => externalRepo.id === repositoryMapping.externalRepositoryId)[0];
					return repository !== undefined ? <Grid item key={repositoryMapping.id} sx={{ mb: 1 }}>
						<SingleRepositoryMappingTable projectUuid={projectUuid ?? ""} repository={repository} repositoryMapping={repositoryMapping} />
					</Grid> : <div key={repositoryMapping.id} />
				})}
			</Grid>
			<AddRepositoryMappingDialog ceeProjectUuid={projectUuid} open={addRepositoryMappingDialogOpen} onClose={() => setAddRepositoryMappingDialogOpen(false)} />
		</StyledBox>
	)
}

interface SingleRepositoryMappingTableProps {
	projectUuid: string;
	repository: ExternalRepositoryDto;
	repositoryMapping: RepositoryMappingDto;
}

const SingleRepositoryMappingTable: React.FC<SingleRepositoryMappingTableProps> = (props) => {

	const { projectUuid, repository, repositoryMapping } = props;

	const [context] = useContext(Context);
	const apiClient = useApiClient();
	const queryClient = useQueryClient();

	const [newProjectPath, setNewProjectPath] = useState("");
	const [newRepositoryPath, setNewRepositoryPath] = useState("");

	const editRepositoryMapping = useMutation((repositoryMapping: Partial<RepositoryMappingDto>) => apiClient
		.patch(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositoryMappings/`, { json: repositoryMapping, searchParams: { replace: true } })
		.json<RepositoryMappingDto>(),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["project-repository-mappings", { projectUuid: projectUuid }]);
				setNewProjectPath("");
				setNewRepositoryPath("");
			},
			onError: (error, variables, context) => console.error(error)
		}
	)

	const deleteRepositoryMapping = useMutation((repositoryMapping: RepositoryMappingDto) => apiClient
		.delete(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositoryMappings/${repositoryMapping.id}`),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["project-repository-mappings", { projectUuid: projectUuid }]);
				setNewProjectPath("");
				setNewRepositoryPath("");
			},
			onError: (error, variables, context) => console.error(error)
		}
	)

	return (
		<>
			<Paper variant="outlined" sx={{ backgroundColor: "#ecedef", p: "0.5em", borderBottom: "0px", borderBottomLeftRadius: 0, WebkitBorderBottomRightRadius: 0 }}>
				<Grid container direction="row" justifyContent="space-between" spacing="1em">
					<Grid item>
						<Grid container direction="column">
							<Grid item>
								<Typography sx={{ fontWeight: 500 }}>
									{repositoryMapping.name}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="caption">
									Repository Name: {repository.name}<br />
									Repository ID: {repository.id}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Tooltip title="Unlink" arrow>
							<ConfirmationDialogIconButton
								size="small"
								onConfirmation={() => deleteRepositoryMapping.mutate(repositoryMapping)}
							>
								<LinkOffIcon />
							</ConfirmationDialogIconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</Paper>
			<Divider />
			<Paper variant="outlined" sx={{ borderTop: "0px", pt: 1, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell width="40%">Repository Path</TableCell>
								<TableCell width="1"><ArrowRightAltIcon /></TableCell>
								<TableCell width="40%">Project Path</TableCell>
								<TableCell />
								<TableCell width="1" />
							</TableRow>
						</TableHead>
						<TableBody>
							{Object.keys(repositoryMapping.mappingData ?? {}).map((mappingDataKey, index, array) =>
								<TableRow key={`${repositoryMapping.id}-${index}`}>
									<TableCell>
										<EditableTextField
											value={mappingDataKey ?? ""}
											onSave={(newValue) => editRepositoryMapping.mutateAsync({ ...repositoryMapping, mappingData: _.omit({ ...repositoryMapping.mappingData, [newValue]: repositoryMapping.mappingData?.[mappingDataKey] }, [mappingDataKey]) as { [key: string]: string } }).then(() => { })}
											fullWidth
										/>
									</TableCell>
									<TableCell />
									<TableCell>
										<EditableTextField
											value={repositoryMapping.mappingData?.[mappingDataKey] ?? ""}
											onSave={(newValue) => editRepositoryMapping.mutateAsync({ ...repositoryMapping, mappingData: { ...repositoryMapping.mappingData, [mappingDataKey]: newValue } }).then(() => { })}
											fullWidth
										/>
									</TableCell>
									<TableCell />
									<TableCell >
										<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="center">
											<Grid item>
												<Tooltip title="Delete" arrow>
													<ConfirmationDialogIconButton
														size="small"
														onConfirmation={() => editRepositoryMapping.mutateAsync({ ...repositoryMapping, mappingData: _.omit({ ...repositoryMapping.mappingData }, [mappingDataKey]) as { [key: string]: string } })}
													>
														<DeleteIcon />
													</ConfirmationDialogIconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</TableCell>
								</TableRow>)}
							<TableRow>
								<TableCell>
									<TextField
										label="Repository Path"
										size="small"
										variant="standard"
										value={newRepositoryPath}
										onChange={(e) => setNewRepositoryPath(e.target.value)}
										fullWidth
									/>
								</TableCell>
								<TableCell />
								<TableCell>
									<TextField
										label="Project Path"
										variant="standard"
										size="small"
										value={newProjectPath}
										onChange={(e) => setNewProjectPath(e.target.value)}
										fullWidth
									/>
								</TableCell>
								<TableCell />
								<TableCell>
									<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="center">
										<Grid item>
											<Tooltip title="Add new mapping">
												<IconButton
													onClick={() => { editRepositoryMapping.mutateAsync({ ...repositoryMapping, mappingData: { ...repositoryMapping.mappingData, [newProjectPath]: newRepositoryPath } }).then(() => { }) }}
												>
													<AddIcon />
												</IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	)
}

export default RepositoryMappingTable;