export enum JobStatus {
	/** Job is waiting for being scheduled */
	WAITING = "WAITING",
	/** Job has been assigned to a platform but not accepted yet */
	ASSIGNED = "ASSIGNED",
	/** Job has been assigned to and accepted by a platform but not yet executed */
	PENDING = "PENDING",
	/** Job is currently under execution */
	RUNNING = "RUNNING",
	/** Job has finished successfully */
	FINISHED = "FINISHED",
	/** Job cancellation on platform is due */
	CANCELLATION_REQUESTED = "CANCELLATION_REQUESTED",
	/** Job has been aborted by a user */
	CANCELED = "CANCELED",
	/** Job shall not be executed and execution has never started. Functionally equivalent to CANCELED. */
	SKIPPED = "SKIPPED",
	/** Job has been rejected by assigned platform before execution. */
	REJECTED = "REJECTED",
	/** Status is not known, e.g. because JOB has been accepted by a platform which then went offline DURING execution. */
	NOT_IN_SYNC = "NOT_IN_SYNC",
	/** Job caused an error on the platform but can be retried on another platform */
	ERROR_RETRY = "ERROR_RETRY",
	/** Job did cause an error. Another execution will not be retried */
	ERROR_FINAL = "ERROR_FINAL"
}

export interface JobDto {
	uuid: string;
	function?: string;
	displayName?: string;
	platformId?: string;
	platformDisplayName?: string;
	ownerUserId?: string;
	ownerDisplayName?: string;
	authorizationScope?: string;
	parameters?: Map<string, string>;
	jobStatus?: JobStatus;
	platformRequirement?: PlatformRequirement;
	created?: string;
	executed?: string;
	finished?: string;
	orphaned?: string;
	pendingUpdates?: JobUpdate[];
	appliedUpdates?: JobUpdate[];
	failureMessage?: string;
	progress?: number,
	estimatedCompletion?: string;
	currentActivity?: string,
	additionalInformation?: Map<string, string>;
	files?: Map<string, JobFile>;
	results?: Map<string, string>;
	dependencies?: JobDependency[];
	jobGroup?: string
}

export interface JobGroupDto {
	uuid?: string
	created: string
	jobs?: JobDto[]
}

export interface JobDependency {
	job?: JobDto
	allowedToFail?: boolean
}

export interface JobUpdate {
	uuid: string;
	updateFunction: string;
	parameters?: Map<string, string>;
	userId: string;
}

export interface PlatformRequirement {
	type?: string;
	topLevelType?: string;
	propertyRequirements?: PropertyRequirement[];
}

export type PropertyRequirement = MultiValuePropertyRequirement | SimpleStringPropertyRequirement;

export interface MultiValuePropertyRequirement {
	type?: string;
	description?: string;
	propertyId?: string;
	values?: string[];
}

export interface SimpleStringPropertyRequirement {
	type?: string;
	description?: string;
	propertyId?: string;
	value?: string;
}

export interface JobFile {
	fileId: string;
	name?: string;
	storageServiceBaseUrl: string;
	type: string;
}

