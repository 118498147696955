import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useCallback } from 'react';
import PipelineTriggerDto from "../../../model/services/project-service/PipelineTriggerDto";
import ConfirmationDialogIconButton from "../../ConfirmationDialogIconButton";
import EditableTextField from '../../EditableTextField';

interface EditorProps<T> {
	value: T;
	onChange: (value: T) => void;
}

const PipelineTriggerEditor: React.FunctionComponent<EditorProps<PipelineTriggerDto[]>> = (props) => {
	const { value, onChange } = props;

	const add = useCallback((newEntry: PipelineTriggerDto) => {
		onChange([...value, newEntry]);
	}, [onChange, value]);

	// function update(i: number, newEntry: PipelineTriggerDto) {
	// 	let newValue = [...value];
	// 	newValue.splice(i, 1, newEntry);
	// 	onChange(newValue);
	// }

	const remove = useCallback((i: number) => {
		let newValue = [...value];
		newValue.splice(i, 1);
		onChange(newValue);
	}, [onChange, value]);

	const update = useCallback((i: number, o: Partial<PipelineTriggerDto>) => {
		let newValue = [...value];
		newValue[i] = { ...newValue[i], ...o };
		onChange(newValue);
	}, [onChange, value]);

	return (
		<Table width='100%' size="small">
			<TableHead>
				<TableRow>
					<TableCell width="1">Enabled</TableCell>
					<TableCell width="20%">Name</TableCell>
					<TableCell >Description</TableCell>
					<TableCell width="1" />
				</TableRow>
			</TableHead>
			<TableBody>
				{value?.map((trigger, i) => <TableRow key={i} sx={{ opacity: trigger.enabled ? 1 : 0.4 }}>
					<TableCell><Checkbox checked={trigger.enabled} onChange={e => update(i, { enabled: e.target.checked })} /></TableCell>
					<TableCell><EditableTextField value={trigger.name ?? ''} onSave={(name) => { update(i, { name }); return Promise.resolve() }} /></TableCell>
					<TableCell><EditableTextField value={trigger.description ?? ''} onSave={(description) => { update(i, { description }); return Promise.resolve() }} /></TableCell>
					<TableCell width="1">
						<ConfirmationDialogIconButton
							onConfirmation={() => remove(i)}
						>
							<DeleteIcon />
						</ConfirmationDialogIconButton>
					</TableCell>
				</TableRow>)}
				<TableRow>
					<TableCell colSpan={2}>
						<Grid container gap={2}>
							<Button onClick={() => add({ id: undefined, name: 'Click to edit', description: 'Click to edit', secret: '', enabled: true })}>Add Trigger</Button>
						</Grid>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default PipelineTriggerEditor;