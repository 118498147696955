import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from "react-query";
import { $enum } from "ts-enum-util";
import { Context } from "../../context/ContextStore";
import useApiClient from "../../hooks/useApiClient";
import ExternalRepositoryDto, { ExternalRepositoryType } from "../../model/services/project-service/ExternalRepositoryDto";

interface AddEditRepositoryDialogProps {
	projectUuid: string | undefined;
	existingRepository: ExternalRepositoryDto | undefined;
	open: boolean;
	onClose: () => void;
}

const AddEditRepositoryDialog: React.FunctionComponent<AddEditRepositoryDialogProps> = (props) => {

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const { projectUuid, existingRepository, open, onClose } = props;

	const [name, setName] = useState("");
	const [type, setType] = useState<ExternalRepositoryType>();
	const [url, setUrl] = useState("");
	const [branch, setBranch] = useState("");

	const queryClient = useQueryClient();

	useEffect(() => {
		setName("");
		setType(ExternalRepositoryType.Git);
		setUrl("");
		setBranch("");

		if (existingRepository) {
			setName(existingRepository.name ?? "");
			setType(existingRepository.type ?? undefined);
			setUrl(existingRepository.url ?? "");
			setBranch(existingRepository.branch ?? "");
		}
	}, [open, existingRepository])

	const createRepository = useMutation((repositoryToCreate: ExternalRepositoryDto) => apiClient
		.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositories`, { json: repositoryToCreate })
		.json<ExternalRepositoryDto>(),
		{
			onSuccess: () => queryClient.invalidateQueries(["project-repositories", { projectUuid: projectUuid }])
		}
	)

	const editRepository = useMutation((repostoryToEdit: ExternalRepositoryDto) => apiClient
		.patch(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositories/${repostoryToEdit.id}`, { json: repostoryToEdit })
		.json<ExternalRepositoryDto>(),
		{
			onSuccess: () => queryClient.invalidateQueries(["project-repositories", { projectUuid: projectUuid }])
		}
	)

	function handleSave() {
		let dto = new ExternalRepositoryDto();

		if (existingRepository) {
			dto.id = existingRepository.id;
		}

		dto.name = name;
		dto.type = type;
		dto.url = url;
		dto.branch = branch;

		if (existingRepository) {
			editRepository.mutateAsync(dto)
				.then(() => onClose())
				.catch((error) => {
					console.error(error);
				});
		} else {
			createRepository.mutateAsync(dto)
				.then(() => onClose())
				.catch((error) => {
					console.error(error);
				});
		}
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>{existingRepository ? "Edit" : "Connect"} Repository</DialogTitle>
			<DialogContent>
				<Grid container direction="column" spacing="1em" paddingTop="1em" width="100%">
					<Grid item>
						<TextField
							label="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							fullWidth
							required
						/>
					</Grid>
					<Grid item>
						<FormControl fullWidth>
							<InputLabel id="repo-type-label">Type</InputLabel>
							<Select label='Type' labelId="repo-type-label" value={$enum(ExternalRepositoryType).getKeyOrDefault(type, "")} onChange={(e) => setType($enum(ExternalRepositoryType).getValueOrDefault(e.target.value, undefined))}>
								{Object.keys(ExternalRepositoryType).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item>
						<TextField
							label="URL"
							value={url}
							onChange={(e) => setUrl(e.target.value)}
							fullWidth
							required
						/>
					</Grid>
					<Grid item>
						<TextField
							label="Branch"
							value={branch}
							onChange={(e) => setBranch(e.target.value)}
							fullWidth
							required
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => { onClose() }}
				>
					Close
				</Button>
				<Button
					variant="contained"
					onClick={() => { handleSave() }}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog >
	)
}

export default AddEditRepositoryDialog;