import { Alert, Box, Button, Collapse, Grid, Paper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../context/ContextStore";
import useApiClient from '../../../hooks/useApiClient';
import CeeProjectDto from "../../../model/services/project-service/CeeProjectDto";
import ConfirmationButton from "../../ConfirmationButton";

interface SingleProjectSettingsProps {
	newProject: boolean
}

const DEFAULT_BRANCH = "main";

const SingleProjectSettings: React.FunctionComponent<SingleProjectSettingsProps> = (props) => {
	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const { newProject } = props;

	const [projectName, setProjectName] = useState('');
	const [internalBranch, setInternalBranch] = useState(DEFAULT_BRANCH);
	const [version, setVersion] = useState('');
	// const [platformRequirements, setPlatformRequirements] = useState('');

	const [projectNameTouched, setProjectNameTouched] = useState(false);
	const [internalBranchTouched, setInternalBranchTouched] = useState(false);
	const [versionTouched, setVersionTouched] = useState(false);
	// const [platformRequirementsTouched, setPlatformRequirementsTouched] = useState(false);

	const [projectNameError, setProjectNameError] = useState(false);
	const [internalBranchError, setInternalBranchError] = useState(false);
	const [versionError, setVersionError] = useState(false);
	// const [platformRequirementsError, setPlatformRequirementsError] = useState(false);

	// const [locked, setLocked] = useState(false);

	const navigate = useNavigate();
	const { ceeProjectUuid } = useParams();

	const queryClient = useQueryClient();

	// Checks for errors in the input fields
	useEffect(() => {
		setProjectNameError(projectNameTouched && projectName.length === 0);
		setInternalBranchError(internalBranchTouched && internalBranch.length === 0);
		setVersionError(versionTouched && version.length === 0);
		// setPlatformRequirementsError(platformRequirementsTouched && platformRequirements.length === 0);
	}, [projectName, projectNameTouched, internalBranch.length, internalBranchTouched, version, versionTouched])

	const getProjectQuery = useQuery(["cee-project", { ceeProjectUuid }], () => apiClient
		.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}`)
		.json<CeeProjectDto>(),
		{
			enabled: !newProject || !!ceeProjectUuid
		}
	)

	const ceeProject = getProjectQuery.data;

	useEffect(() => {
		setProjectName(ceeProject?.name ?? "");
		setVersion(ceeProject?.version ?? "");
		// setPlatformRequirements(ceeProject?.platformRequirementJson ?? "")

		setProjectNameTouched(false);
		setInternalBranchTouched(false);
		setVersionTouched(false);
		// setPlatformRequirementsTouched(false);

		setProjectNameError(false);
		setInternalBranchError(false);
		setVersionError(false);
		// setPlatformRequirementsError(false);

		if (newProject) {
			setInternalBranch(DEFAULT_BRANCH);
		}
	}, [ceeProject, newProject]);

	const createProject = useMutation((projectToCreate: CeeProjectDto) => apiClient
		.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects`, { searchParams: { initialBranch: internalBranch }, json: projectToCreate })
		.json<CeeProjectDto>()
		, {
			onSuccess: (response) => {
				queryClient.invalidateQueries("cee-projects");
				navigate(`../${response.uuid}`);
			}
		});

	const editProject = useMutation((projectToEdit: CeeProjectDto) => apiClient
		.patch(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectToEdit.uuid}`, { json: projectToEdit })
		.json<CeeProjectDto>()
		, {
			onSuccess: (response, projectToEdit, context) => {
				queryClient.invalidateQueries("cee-projects");
				queryClient.invalidateQueries(["cee-project", { ceeProjectUuid: projectToEdit.uuid }])
			}
		});

	const deleteProject = useMutation((projectToDelete: CeeProjectDto) => apiClient
		.delete(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectToDelete.uuid}`)
		, {
			onSuccess: (response, projectToDelete, context) => {
				queryClient.invalidateQueries("cee-projects").then(() => {
					queryClient.removeQueries(["cee-project", { ceeProjectUuid: projectToDelete.uuid }]);
					localStorage.removeItem("selectedProject");
					navigate("/projects");
				});
			}
		});

	function handleSave() {
		let temporaryProject = new CeeProjectDto();
		temporaryProject.name = projectName;
		temporaryProject.version = version;
		// temporaryProject.platformRequirementJson = platformRequirements;

		if (ceeProject) {
			temporaryProject.uuid = ceeProject.uuid;
		}

		if (newProject) {
			createProject.mutate(temporaryProject);
		} else {
			editProject.mutate(temporaryProject)
		}
	}

	const isLoading = createProject.isLoading || editProject.isLoading || deleteProject.isLoading;
	const isError = createProject.isError || editProject.isError || deleteProject.isError;
	const error = createProject.error || editProject.error || deleteProject.error;

	return (
		<Grid container direction="row" justifyContent="center">
			<Grid item xs={12} sm={10} md={8} xl={6}>
				<Paper>
					<Box component='form' noValidate autoComplete='off' overflow='auto'>
						<Grid container direction='column' spacing={2} padding={2}>
							<Grid item><Typography variant="h5">{newProject ? "Create A New Project" : `Edit Project ${projectName}`}</Typography></Grid>
							<Grid item>
								<Collapse in={isError}><Alert severity="error">{String(error || '')}</Alert></Collapse>
								<Collapse in={editProject.isSuccess}><Alert severity="success">Changes saved!</Alert></Collapse>
							</Grid>
							<Grid item>
								<TextField
									id='project-name'
									label='Name'
									sx={{ width: '100%' }}
									value={projectName}
									onChange={(event) => {
										setProjectNameTouched(true);
										setProjectName(event.target.value);
										createProject.reset();
										editProject.reset();
									}}
									error={projectNameError}
									disabled={isLoading}
									required
								/>
							</Grid>
							{newProject && <Grid item>
								<TextField
									id='internal-repo-branch'
									label='Internal Branch'
									sx={{ width: '100%' }}
									value={internalBranch}
									onChange={(event) => {
										setInternalBranchTouched(true);
										setInternalBranch(event.target.value);
										createProject.reset();
										editProject.reset();
									}}
									error={internalBranchError}
									disabled={isLoading}
									required
								/>
							</Grid>}
							<Grid item>
								<TextField
									id='project-version'
									label='Version'
									sx={{ width: '100%' }}
									value={version}
									onChange={(event) => {
										setVersionTouched(true);
										setVersion(event.target.value);
										createProject.reset();
										editProject.reset();
									}}
									error={versionError}
									disabled={isLoading}
									required
								/>
							</Grid>
							{/* <Grid item>
						<TextField
							id='project-platform-requirement-json'
							label='Platform Requirement JSON'
							multiline
							sx={{ width: '100%' }}
							value={platformRequirements}
							onChange={(event) => {
								setPlatformRequirementsTouched(true);
								setPlatformRequirements(event.target.value);
								createProject.reset();
								editProject.reset();
							}}
							error={platformRequirementsError}
							disabled={isLoading}
						/>
					</Grid> */}
							<Grid item>
								<Grid container direction='row' gap={2}>
									<Button variant="outlined" onClick={handleSave} disabled={isLoading || (!ceeProject && !newProject)}>{newProject ? "Create" : "Update"}</Button>
									{!newProject && ceeProject && <ConfirmationButton variant="outlined" color="error" confirmationLabel="Really?" onClick={() => deleteProject.mutate(ceeProject)} disabled={isLoading || !ceeProject}>Delete</ConfirmationButton>}
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default SingleProjectSettings;